.topMenu {
  height: 70px;
  text-align: center;
  width: 100%;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  align-items: center;
  display: flex;
  -webkit-box-pack: space-between;
      -ms-flex-pack: space-between;
          justify-content: space-between;
}
/*# sourceMappingURL=menu.css.map */
body {
  background-color: #f0f2f5 !important;
}

ol,
ul {
  padding: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  padding: 0;
  margin: 0 !important;
}

* {
  font-family: "Quicksand";
  font-weight: 600;
}

.loaderFixed {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(81, 191, 164, 1);
  z-index: 99999;
}

.bg-white {
  background-color: white;
}

.ant-table {
  background-color: white;
}
.ant-table-pagination.ant-pagination {
  margin: 15px 0 0 0 !important;
}
.w-100 {
  width: 100%;
}
.fl {
  float: left;
}
.floater {
  float: left;
  width: 100%;
}

.flex {
  display: flex;
}
.flex.center {
  justify-content: center;
  align-items: center;
}
.bold {
  font-weight: bold;
}
/* colors */
.red {
  color: red;
}

/* loader */
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.goBack {
  padding: 10px;
  background-color: white;
  width: 70px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px !important;
  float: left;
}

.flex {
  display: flex;
  align-items: center;
}

/* widths */
.w-100 {
  width: 100%;
  float: left;
}

/* margins */
.mt-10 {
  margin-top: 10px !important;
}
.margin0 {
  margin: 0 !important;
}

/* theme edit */
.theme-edit-parts {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
}
.two-items {
  display: flex;
  justify-content: space-between;
}
.two-items .two-item {
  width: 48%;
}

/* message part */
.message-part {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.message-part span {
  font-weight: bold;
}